import React from "react";
import "../styles/Secondary.css";

const NBASection = () => (
    <section className="secondary-content-section" id="nba-content">
        <h1>NBA Performance Prediction Model</h1>
        <div className="header-image-nba"></div>
        <p className="photo-credits">
            Photo by <a href="https://unsplash.com/@timhart0421">Tim Hart</a> on{" "}
            <a href="https://unsplash.com/">Unsplash</a>
        </p>
        <h2>The Goal</h2>
        <p>
            The goal of this project is simple: build a machine learning model
            that predicts how many points an NBA player will score on a given
            night with <i>relatively</i> high accuracy. I italicize “relatively”
            because, while the model can identify patterns and trends, the
            unpredictable nature of sports means that perfect accuracy is
            impossible.
        </p>
        <h2>Importance of Feature Selection</h2>
        <p>
            While machine learning models are powerful, their accuracy depends
            heavily on the <strong>quality of the data</strong>. Before building
            the model, I carefully considered which features would yield the
            most predictive power. A commonly used datapoint is the amount of
            points an NBA team allows against specific positions (guards,
            forwards, centers). However, I realized that this approach had
            limitations due to the positionless nature of modern NBA play.
        </p>
        <h2>The Problem with Position-Based Data</h2>
        <p>
            In today's NBA, a player's listed position has minimal effect on
            their playstyle and scoring tendencies. For example, both Giannis
            Antetokounmpo and Kevin Durant are categorized as forwards, yet
            their styles of play differ significantly. Giannis dominates in the
            paint, while Durant excels with mid-range and three-point shooting.
            Grouping these players under the same positional umbrella fails to
            capture these factors, reducing prediction accuracy.
        </p>
        <h2>Leveraging Random Forest Regression</h2>
        <p>
            To address this, I utilized{" "}
            <strong>random forest regression</strong>, a powerful machine
            learning algorithm that builds multiple decision trees and combines
            their outputs to improve prediction accuracy. This approach allows
            the model to capture complex interactions and relationships among
            various features. Random forest regression has two key advantages:
            <ol>
                <li>
                    <strong>Improved Accuracy:</strong> By analyzing a wide
                    range of features and their interactions, random forest
                    models are well-suited to capture the complexities of player
                    scoring tendencies, leading to more accurate predictions.
                </li>
                <li>
                    <strong>Combating Overfitting:</strong> The nature of random
                    forests helps mitigate the risk of overfitting, resulting in
                    a model that generalizes better to unseen data (test data).
                </li>
            </ol>
        </p>
        <h2>Model Performance and Future Work</h2>
        <p>
            By using random forest regression, I built a model that successfully
            predicts over/under lines with <strong>60% accuracy</strong>,
            compared to the <strong>52% fair odds</strong> set by DraftKings.
            This project shows the critical role of feature engineering and
            model selection in improving performance. Moving forward, I plan to
            refine the feature set and incorporate additional data sources to
            enhance accuracy.
        </p>
    </section>
);

export default NBASection;
