import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AboutSection from "./components/AboutSection";
import CryptoSection from "./components/CryptoSection";
import NBASection from "./components/NBASection";
import EVSection from "./components/EVSection";
import PortfolioSection from "./components/PortfolioSection";
import WorkContent from "./components/Work";

// Map paths to titles
const titlesMap = {
  "/about": "Brandon Lee - About",
  "/cryptocurrency": "Brandon Lee - Cryptocurrency",
  "/nba-proj-model": "Brandon Lee - NBA Proj Model",
  "/positive-ev-tracker": "Brandon Lee - Positive EV Tracker",
  "/portfolio": "Brandon Lee - Portfolio",
};

const App = () => {
  // Keep track of current location
  const location = useLocation();

  // Update the webpage's title when the route changes
  useEffect(() => {
    document.title = titlesMap[location.pathname] || "Brandon Lee";
  }, [location.pathname]);

  // Change navbar appearance on scroll
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      const triggerHeight = 100;
      if (window.scrollY > triggerHeight) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Return the correct route
  return (
    <div className="app-root">
      <Navbar />
      <Routes>
        <Route path="/about" element={<AboutSection />} />
        <Route path="/cryptocurrency" element={<CryptoSection />} />
        <Route path="/nba-proj-model" element={<NBASection />} />
        <Route path="/positive-ev-tracker" element={<EVSection />} />
        <Route path="/portfolio" element={<PortfolioSection />} />
        <Route path="/" element={<WorkContent />} />
      </Routes>
      <Footer />
    </div>
  );
};

// Wrap the App component in Router to handle user navigation
const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
