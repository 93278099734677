import React from "react";
import "../styles/Secondary.css";

const CryptoSection = () => (
  <section className="secondary-content-section" id="crypto-content">
    <h1>Cryptocurrency Wallet Monitoring Bot</h1>
    <div className="header-image-crypto"></div>
    <p className="photo-credits">
      Photo by <a href="https://unsplash.com/@traxer">Traxer</a> on{" "}
      <a href="https://unsplash.com/">Unsplash</a>
    </p>
    <h2>Background</h2>
    <p>
      Cryptocurrencies like Bitcoin changed the game by allowing peer-to-peer
      transactions without banks. Then came the memecoins, like Dogecoin and
      Shiba Inu, adding a whole new level of internet-fueled hype to the mix.
      While it's nearly impossible to predict which memecoins will skyrocket, I
      built a bot with a different goal: to track cryptocurrency wallets that
      consistently find these coins early. In this context, a{" "}
      <strong>wallet</strong> refers to an address on the blockchain where
      traders store their cryptocurrency and execute buy/sell transactions.
      Consistently profitable wallets often have a strong social media presence
      or connections to influencers who do, giving them an edge over the average
      trader.
    </p>
    <p>
      I chose the <strong>Solana blockchain</strong> for this project, not only
      because I'm most familiar with it, but also because it's known for having
      a high volume of memecoins. One key aspect of cryptocurrency is that all
      transactions are public—when a trader buys or sells a coin, it's visible
      on the blockchain. A quick API request provides detailed information on
      each transaction, including the coin, trade amount, date, and more.
    </p>
    <h2>Data Collection</h2>
    <p>
      With millions of traders on the Solana blockchain, finding wallets worth
      tracking can be difficult. Here's the approach I personally used to
      identify potentially valuable wallets:
      <ol>
        <li>
          <strong>
            <a
              href="https://photon-sol.tinyastro.io/en/discover"
              target="noreferrer"
            >
              Photon's Discover Page
            </a>
          </strong>
          : I started by identifying the top trending coins using Photon's
          discover feature.
        </li>
        <li>
          <strong>
            <a href="https://dexwhales.xyz/" target="noreferrer">
              Dexwhales
            </a>
          </strong>
          : Next, I used Dexwhales to locate the top 100 most profitable traders
          for each coin, scraping their wallet addresses and storing them in a
          text file.
        </li>
        <li>
          <strong>Bot Analysis:</strong> Finally, I fed the collected wallet
          addresses into the bot for detailed analysis.
        </li>
      </ol>
    </p>

    <h2>Analyzing the Data</h2>
    <p>
      The bot goes beyond simple transaction tracking by performing in-depth
      analysis of key trading metrics. When a trader buys a coin, it adds the
      transaction to their portfolio, and when they sell, it subtracts the
      amount using a <strong>First In, First Out (FIFO)</strong> cost-basis. It
      also calculates trade duration and position size to filter out bots making
      rapid, small trades. Additionally, the bot excludes traders with a
      negative <strong>profit and loss (PNL)</strong>, focusing on those who are
      consistently profitable and make well-timed, precise trades—ideal
      candidates for future tracking.
    </p>

    <h2>Speeding Things Up</h2>
    <p>
      Processing thousands of transactions for each wallet was a time-consuming
      task, so I explored two strategies to improve efficiency: batch requests
      and parallel processing.
      <ol>
        <li>
          <strong>Batch Requests:</strong> To reduce the number of API calls, I
          grouped multiple transactions into a single request, significantly
          cutting down on the amount of calls that were made to the API. For
          instance, when processing 100,000 transactions, I had two options:
          send 100,000 individual requests or group them into batches of 1,000,
          reducing it to just 100 requests. This minimized waiting times and
          decreased the likelihood of hitting rate limits, making the process
          much more efficient.
        </li>
        <li>
          <strong>Parallel Processing:</strong> Batch requests helped, but I
          needed more speed. Using Python's <strong>concurrent.futures</strong>,
          I enabled the bot to handle multiple wallet addresses simultaneously.
          This approach reduced the total runtime from 25 minutes to just 4
          minutes, making the bot much more efficient and scalable.
        </li>
      </ol>
    </p>

    <h2>Final Thoughts</h2>
    <p>
      Building the Cryptocurrency Wallet Monitoring Bot was a rewarding
      challenge, with the biggest hurdles being not only filtering out
      unprofitable traders but also optimizing the processing speed. Given the
      unpredictable nature of memecoins, consistently identifying valuable
      traders is difficult, but this tool is designed to make that task a bit
      easier. Future efforts will focus on refining the data collection and
      analysis process to better pinpoint the most valuable wallets for even
      greater accuracy and insight.
    </p>
  </section>
);

export default CryptoSection;
